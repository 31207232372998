.login-form {
  width: 580px;
  padding: 20px;
  max-width: 580px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-form h1 {
  text-align: center;
  font-size: 24px;
  margin-bottom: 40px;
  transform: translateX(0px);
}

.login-form h1 span {
  display: block;
  font-size: 32px;
  margin-top: 20px;
}
@media screen and (max-width: 786px) {
  .login-form {
    width: 90%;
  }
  .login-form h1 {
    transform: translateX(0px);
    font-size: 24px;
  }
}
