.payment {
  border-bottom: 1px solid var(--silver);
}
.payment:hover {
  background-color: #f0f0f0;
}
.payments-list-header {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 20px;
  font-size: 20px;
}
.payments-list-header-number-of-payments,
.payments-list-header-total-payments {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid var(--silver);
  border-radius: 4px;
}

.payment p {
  font-size: 14px;
  font-weight: 300;
}
.payment-user {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
}

.payment-user span {
  display: block;
  width: 22px;
  height: 22px;
  border: 1px solid rgba(255, 0, 0, 0.5);
  border-radius: 50%;
  position: relative;
  justify-self: end;
}

.payment-user span svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.payment-done-by {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  font-size: 14px;
  font-weight: 400;
}
.payment-date,
.payment-last-update {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.payment-exp-date {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-id,
.payment-month {
  display: flex;

  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 500;
}
.payment-action {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.payment-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.payment-action svg {
  transform: translateY(-2px);
}
.payment-amount {
  font-weight: 600;
  color: var(--independance);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Sorter */
.sorter {
  position: relative;
}
.sorter span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 30px;
  /* background-color: rgba(127, 255, 212, 0.486); */
}
.sorter span svg {
  cursor: pointer;
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: var(--independance);
  opacity: 0.6;
  transition: all 0.2s ease-out;
}
.sorter span svg:hover {
  opacity: 1;
}
.sorter span svg.active {
  opacity: 1;
}
