.show-users {
  position: relative;
  margin: 0 auto;
  padding: 10px;
  overflow-x: auto;
  background-color: rgba(194, 207, 178, 0.5);
}

.users-tab .nav-link {
  color: var(--mummysTomb);
}
.users-tab .nav-link:hover {
  color: var(--independance);
}

/* List image */
.users-list-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
}
.users-list-image-sm {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-block;
}

@media screen and (max-width: 1290px) {
  .users-tab {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .users-tab .nav-link,
  .users-tab .nav-link.active {
    width: 100%;
    text-align: left;
  }
}
