.latest-users {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid var(--silver);
  border-radius: 4px;
}
.latest-users-header h6 {
  font-size: 16px;
  font-weight: 600;
}
.latest-users-user {
  border-bottom: 1px solid var(--silver);
  padding: 10px 0;
}
.latest-user-body {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 5px;
}
.latest-user-body-info {
  padding-top: 7px;
  width: 100%;
}
.latest-user-body-info h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 3px;
}
.latest-user-body-info p {
  font-size: 14px;
}
.latest-user-body-info-contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.latest-user-footer {
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.latest-user-footer-left {
  text-transform: capitalize;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}
.latest-users-footer {
  text-align: right;
  padding: 20px 0px 0px 0px;
}

@media screen and (max-width: 920px) {
  .latest-user-body-info h3 {
    font-size: 18px;
  }
  .latest-user-body-info-contact {
    flex-direction: column;
    align-items: flex-start;
    gap: 1px;
  }
  .latest-user-body-info-contact p {
    margin-bottom: 5px;
  }
}
