.users-list {
  background-color: #f0f0f0;
  border-radius: 10px;
}

.user {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.user-name,
.user-phone,
.user-email,
.rank-user,
.user-status,
.user-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.user-action {
  gap: 5px;
}
.user-name h4 {
  text-align: left;
  font-weight: 400;
  color: var(--independance);
  font-size: 20px;
  margin-bottom: 0;
}

@media screen and (max-width: 1290px) {
  .users-list-heading {
    display: none !important;
  }
  .user-action {
    justify-content: flex-end;
  }
}
/* .users-list-header {
  display: flex;
  justify-content: flex-start;

  gap: 20px;
  padding: 20px;
  align-items: center;
  text-transform: uppercase;
  color: var(--mummysTomb);
  border-bottom: 1px solid var(--mummysTomb);
  background-color: var(--dustStorm);
  border-radius: 10px 10px 0 0;
}
.users-list-header .image {
  width: 50px;

  text-align: center;
}
.users-list-header .name {
  width: 18%;

  text-align: center;
}
.users-list-header .phone {
  width: 15%;
  text-align: center;
}
.users-list-header .email {
  width: 20%;

  text-align: center;
}
.users-list-header .rank {
  width: 5%;
  text-align: center;
}
.users-list-header .status {
  margin-left: auto;
  width: 15%;
  text-align: center;
}
.users-list-header .action {
  justify-self: flex-end;
  width: 15%;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .users-list {
    background-color: #ffffff00;
  }
  .users-list-header {
    display: none;
  }
} */
