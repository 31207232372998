.footer {
  background-color: var(--independance);
  color: white;
  padding: 10px 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 14px;
  font-weight: 300;
}
