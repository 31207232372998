.dashboard-links-users {
  background-color: rgba(255, 166, 0, 0.25);
  border-radius: 4px;
  padding: 20px;
}
.dashboard-links-users-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
