.dashboard-links-payments {
  background-color: rgba(238, 133, 53, 0.25);
  border-radius: 4px;
  padding: 20px;
}
.dashboard-links-payments-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
