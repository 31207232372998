*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --dustStorm: #ddd1c7;
  --silver: #c2cfb2;
  --darkSeaGreen: #8db580;
  --mummysTomb: #7e8987;
  --independance: #4b4a67;
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

html {
  font-family: 'Open Sans', serif;
  background-color: white;
  color: #0f0f0f;
}

h1 {
  font-size: 34px;
  font-weight: 400;
}
h2 {
  font-size: 28px;
  font-weight: 400;
}
h3 {
  font-size: 22px;
  font-weight: 400;
}
/* Lists */

li {
  list-style: none;
}
/* Buttons  */

/* .btn {
  background-color: var(--darkSeaGreen);
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}
.btn:hover,
.btn:active {
  opacity: 0.7;
}
.btn svg {
  display: inline-block;
  transform: translateY(2px);
}
.btn-warning {
  background-color: var(--mummysTomb);
  border: 1px solid var(--mummysTomb);
}
.btn-action {
  background-color: var(--independance);
}
.btn-transparent {
  background-color: transparent;
  color: var(--darkSeaGreen);
  border: 1px solid var(--darkSeaGreen);
}

.btn-search {
  background-color: transparent;
  color: var(--independance);
  padding-top: 12px;
}
.btn-sm {
  padding: 5px 10px;
  font-size: 14px;
}
.btn-md {
  padding: 10px 15px;
  font-size: 16px;
}
.btn-lg {
  padding: 15px 25px;
  font-size: 18px;
} */
/* Forms  */
form .form-row {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

form input,
form select,
form textarea {
  width: 100%;
  display: block;
  padding: 10px;

  font-size: 18px;
  color: var(--independance);
  outline: none;
  border: 1px solid var(--mummysTomb);
  border-radius: 4px;
}

form input:focus {
  outline: none;
  border-color: var(--mummysTomb);
}
form label {
  min-width: 150px;
  text-align: right;
  padding: 10px;
  color: var(--mummysTomb);
}

.form-button {
  text-align: right;
  margin: 40px 0;
}

@media screen and (max-width: 768px) {
  form .form-row {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  form label {
    min-width: 150px;
    text-align: left;
    padding: 7px 2px;
  }
}

/*  Error message */

.error-message {
  color: var(--darkSeaGreen);
  padding: 10px;
  border: 1px solid var(--dustStorm);
  margin: 10px 0;
  border-radius: 4px;
  width: auto;
}

.App {
  padding-bottom: 100px;
  display: flex;
  /* align-items: flex-start; */

  /* New code */
  align-items: strech;
  width: 100%;
}

.main-container {
  /*  width: 100%; */
  flex: 1;
}

.rounded-image {
  border-radius: 10px;
}

/* Ranking System */

.user-rank {
  width: 50px;
  padding: 0px;
}
.user-rank-1,
.user-rank-2 {
  height: 5px;
}

/* White */
.user-rank-white .user-rank-1,
.user-rank-white .user-rank-2 {
  background-color: white;
}

/* blue */
.user-rank-blue .user-rank-1,
.user-rank-blue .user-rank-2 {
  background-color: rgb(33, 33, 192);
}

/* purple */
.user-rank-purple .user-rank-1,
.user-rank-purple .user-rank-2 {
  background-color: purple;
}
/* brown */
.user-rank-brown .user-rank-1,
.user-rank-brown .user-rank-2 {
  background-color: brown;
}

/* black */
.user-rank-black .user-rank-1,
.user-rank-black .user-rank-2 {
  background-color: black;
}

/*gray  */
.user-rank-gray-white .user-rank-1 {
  background-color: gray;
}
.user-rank-gray-white .user-rank-2 {
  background-color: white;
}
.user-rank-gray .user-rank-1,
.user-rank-gray .user-rank-2 {
  background-color: gray;
}
.user-rank-gray-black .user-rank-1 {
  background-color: gray;
}
.user-rank-gray-black .user-rank-2 {
  background-color: black;
}

/*yellow*/

.user-rank-yellow-white .user-rank-1 {
  background-color: yellow;
}
.user-rank-yellow-white .user-rank-2 {
  background-color: white;
}
.user-rank-yellow .user-rank-1,
.user-rank-yellow .user-rank-2 {
  background-color: yellow;
}
.user-rank-yellow-black .user-rank-1 {
  background-color: yellow;
}
.user-rank-yellow-black .user-rank-2 {
  background-color: black;
}

/* orange */

.user-rank-orange-white .user-rank-1 {
  background-color: orange;
}
.user-rank-orange-white .user-rank-2 {
  background-color: white;
}
.user-rank-orange .user-rank-1,
.user-rank-orange .user-rank-2 {
  background-color: orange;
}
.user-rank-orange-black .user-rank-1 {
  background-color: orange;
}
.user-rank-orange-black .user-rank-2 {
  background-color: black;
}

/* green */

.user-rank-green-white .user-rank-1 {
  background-color: green;
}
.user-rank-green-white .user-rank-2 {
  background-color: white;
}
.user-rank-green .user-rank-1,
.user-rank-green .user-rank-2 {
  background-color: green;
}
.user-rank-green-black .user-rank-1 {
  background-color: green;
}
.user-rank-green-black .user-rank-2 {
  background-color: black;
}

/* Tooltip */

.tooltip-custom {
  position: relative;
}
.tooltip-custom span {
  display: block;

  background-color: var(--dustStorm);
  color: var(--independance);
  font-size: 14px;
  padding: 5px 7px;
  border-radius: 4px;
  white-space: nowrap;

  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
  display: none;
  transition: all 0.2s ease;
  opacity: 1;
}

.tooltip-custom:hover span {
  display: block;
  opacity: 1;
}

/* Animations  */

/**
 * ----------------------------------------
 * animation slide-right
 * ----------------------------------------
 */

@-webkit-keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
@keyframes slide-right {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
}
.slide-right {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
