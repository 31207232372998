.latest-payments {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid var(--silver);
  border-radius: 4px;
}
.latest-payments-header h6 {
  font-size: 18px;
  font-weight: 500;
}
.latest-payments .exp-date {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.latest-payments .exp-date span {
  margin-left: 5px;
}
.latest-payments .payment-id {
  font-size: 14px;
  font-weight: 400;
  padding: 2px 8px;
}
