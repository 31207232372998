.total-users {
  margin: 20px 0;
  padding: 20px;
  border: 1px solid var(--silver);
  border-radius: 4px;
}
.total-users-header h6 {
  font-size: 18px;
  font-weight: 500;
}
.total-users-body {
  padding: 0px;
}
.total-users-statistics {
  padding: 10px 0px 0 0px;
  display: flex;
  align-items: center;
  gap: 40px;
  justify-content: space-between;
}
.total-users-statistics h4 {
  font-size: 42px;
}
.total-users-comparation-explanation {
  font-size: 14px;
  text-align: right;
}
.total-users-footer {
  text-align: right;
  padding: 0;
}
