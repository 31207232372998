.update-payment {
  padding: 20px;
}

.update-payment .form-floating > label {
  z-index: 0 !important;
}
.update-payment h2 {
  margin-bottom: 40px;
}
.update-payment .select-user,
.update-payment .select-payment-type,
.update-payment .select-payment-month,
.update-payment .payment-note,
.update-payment .payment-date,
.update-payment .payment-exp {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .update-payment .select-user,
  .update-payment .select-payment-type,
  .update-payment .select-payment-month,
  .update-payment .select-payment-amount,
  .update-payment .payment-note,
  .update-payment .payment-date,
  .update-payment .payment-exp {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .update-payment .select-user,
  .update-payment .select-payment-type,
  .update-payment .select-payment-month,
  .update-payment .select-payment-amount,
  .update-payment .payment-note,
  .update-payment .payment-date,
  .update-payment .payment-exp {
    width: 40%;
  }
}

@media screen and (min-width: 1240px) {
  .update-payment .select-user,
  .update-payment .select-payment-type,
  .update-payment .select-payment-month,
  .update-payment .select-payment-amount,
  .update-payment .payment-note,
  .update-payment .payment-date,
  .update-payment .payment-exp {
    width: 30%;
  }
}
