.dashboard {
  background-color: aliceblue;
  padding: 20px;
}
.dashboard-overview {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
}

@media screen and (max-width: 768px) {
  .dashboard-overview {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0px;
  }
  .dashboard-overview-payments {
    width: 100%;
  }
}
